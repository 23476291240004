@charset "UTF-8";




body > div > main > div > div > div.report_body.logiquest > form > table > tbody > tr:nth-child(2) {
    background-color: #e2efd9;
}
.report_body.logiquest table tr:nth-child(7) ,
.report_body.logiquest table tr:nth-child(12) ,
.report_body.logiquest table tr:nth-child(17) ,
.report_body.logiquest table tr:nth-child(22) ,
.report_body.logiquest table tr:nth-child(27) {
	border-bottom: double;
}
.report_body.logiquest table tr:nth-child(33) {
	border-bottom: solid 1px #000;
}
.report_body.logiquest table td:nth-child(8) {
    border: none;
}
body > div > main > div > div > div.report_body.logiquest > form > table > tbody > tr:nth-child(34) > td:nth-child(4) {
	border-right: 1px solid #fff;
	border-bottom: 1px solid #fff;
}
.report_body.logiquest .report_subtotal {
    background-color: inherit;
	text-align: right;
	font-weight: bold;
    padding: 0.5em 0.3em 0em 0.3em;
}


/* �e�[�u���㕔 */
.report_body.logiquest {
    padding: 2mm 10mm 10mm 10mm;
}
.report_body.logiquest .info_box {
	margin: 0 auto 5px auto;
}
.report_body.logiquest .info_box .inleft {
	display: inline-block;
	width: 58%;
	margin-right: 1%;
    vertical-align: bottom;
}
.report_body.logiquest .info_box .inright {
	display: inline-block;
	width: 40%;
	border-bottom: 1px solid #000;
}
.report_body.logiquest .report_ttl {
    display: inline-block;
    width: 80%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding-left: 0;
    border: 3.5px solid #000;
    border-radius: 6px;
}
.report_body.logiquest .info_box .inright .fax {
    font-size: 18px;
    font-weight: 600;
}
.report_body.logiquest .info_box .inright .fax span {
    font-size: 15px;
}

.report_body.logiquest .info_box.under {
	margin-bottom: -8px;
}
.report_body.logiquest .info_box.under table {
    margin-top: 0;
}
.report_body.logiquest .info_box.under table th {
    width: 26%;
    text-align: left;
    padding: 6px 4px;
}
.report_body.logiquest .info_box.under table td {
    width: 74%;
    padding: 6px 4px;
}
.report_body.logiquest .info_box.under .incenter {
	display: inline-block;
	width: 25%;
	vertical-align: bottom;
}
.report_body.logiquest .info_box.under .incenter table {
	display: inline-block;
	width: 100%;
	vertical-align: bottom;
	border: none;
}
.report_body.logiquest .info_box.under .incenter table th {
	width: 40%;
	font-weight: normal;
}
.report_body.logiquest .info_box.under .incenter table td {
	width: 28%;
	font-weight: 600;
    font-size: 15px;
}
.report_body.logiquest .info_box.under .incenter table tr:nth-child(2) th ,
.report_body.logiquest .info_box.under .incenter table tr:nth-child(2) td {
    padding: 19.35px 4px;
}
.report_body.logiquest .info_box.under .inright {
	display: inline-block;
	width: 14%;
	border-bottom: 0px;
	vertical-align: bottom;
}
.report_body.logiquest .info_box.under .inright p {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}
.report_body.logiquest .info_box.under table input {
    width: auto;
}
.report_body.logiquest input[type="checkbox"] {
  height: 10px;
}

/* �e�[�u������ */
.report_body.logiquest .example_box {
	margin-top: 5px;
	margin-left: auto;
	margin-right: auto;
}
.report_body.logiquest .example_box .inleft {
	display: inline-block;
	width: 44%;
	margin-right: 1%;
	vertical-align: bottom;
}
.report_body.logiquest .example_box .inright {
	display: inline-block;
	width: 54%;
	vertical-align: bottom;
}
.report_body.logiquest .example_box .inright dl dt {
	display: inline-block;
	width: 3%;
	vertical-align: top;
}
.report_body.logiquest .example_box .inright dl dd {
	display: inline-block;
	width: 96%;
	vertical-align: top;
}
.report_body.logiquest .example_box .bold {
	font-weight: bold;
}
.report_body.logiquest .example_box table {
	margin-top: 2px;
}
.report_body.logiquest .example_box table tr:first-child {
	background-color: #ccffff;
}
.report_body.logiquest .example_box table td i {
	font-style: normal;
	font-weight: bold;
	color:#ff0000;
}

.report_body.logiquest .example_box .inright dl {
	margin-top: 18px;
}
.report_body.logiquest .example_box .inright dl dt ,
.report_body.logiquest .example_box .inright dl dd {
	font-weight: 600;
    line-height: 1.4;
}
body > div > main > div > div > div.report_body.logiquest > form > div.example_box > p:nth-child(3) ,
body > div > main > div > div > div.report_body.logiquest > form > div.example_box > p:nth-child(4){
    font-size: 11px;
    font-weight: bold;
}
body > div > main > div > div > div.report_body.logiquest > form > div.example_box > p:nth-child(3)  {
	margin: 5px 0 0 0;
}

.report_body.logiquest .example_box table td {
	width: 5%;
}
.report_body.logiquest .example_box table td:nth-child(1) ,
.report_body.logiquest .example_box table td:nth-child(2) {
	width: 8%;
}
.report_body.logiquest .example_box table td:nth-child(5) {
	width: 2%;
}
.report_body.logiquest .example_box table td:nth-child(10) {
	width: 2%;
}
.report_body.logiquest .example_box table td:nth-child(13) ,
.report_body.logiquest .example_box table td:nth-child(14) ,
.report_body.logiquest .example_box table td:nth-child(15) {
	width: 4%;
}
.report_body.logiquest .example_box table td:nth-child(8) {
	border: 1px solid;
}


/* �e�[�u���@�g�[�^���G���A �������� */
.report_body.logiquest table.total td:nth-child(1) {
	width: 39%;
}
.report_body.logiquest table.total td:nth-child(2) {
	width: 9.72%;
}
.report_body.logiquest table.total td:nth-child(3) {
	width: 11.5%;
}
.report_body.logiquest table.total td:nth-child(4) {
    border: 1px solid #fff;
}

.report_body.logiquest table.total ,
.report_body.logiquest table.total td {
	border-top: 0px ;
    margin-top: 0;
}
/* �e�[�u���@�g�[�^���G���A �����܂� */


/* �e�[�u�����@�����w�� �������� */
.report_body table tr:nth-child(2) th:nth-child(2) {
	width: 5%;
}
.report_body table tr:nth-child(2) th:nth-child(3) {
	width: 5%;
}
.report_body table tr:nth-child(2) th:nth-child(4) {
	width: 10%;
}
.report_body table tr:nth-child(2) th:nth-child(5) {
	width: 10%;
}
.report_body table tr:nth-child(2) th:nth-child(6) {
	width: 9%;
}
.report_body table tr:nth-child(2) th:nth-child(7) {
	width: 14%;
}
.report_body table tr:nth-child(2) th:nth-child(8) {
	width: 5%;
}
.report_body table tr:nth-child(2) th:nth-child(9) {
	width: 5%;
}

.report_body table tr:nth-child(2) th:nth-child(10) {
	width: 10%;
}
.report_body table tr:nth-child(2) th:nth-child(11) {
	width: 15%;
}
.report_body table tr:nth-child(2) th:nth-child(12) {
	width: 15%;
}
.report_body table input.report_distance {
    width: 8mm!important;
}
.report_body table input.report_overtimefee {
    width: 3em!important;
}
/* �e�[�u�����@�����w�� �����܂� */



div.tablewrap{
  position: relative;
}
table.logi-out{
  width: 180px;
  position: absolute;
  left:770px;
  margin-top: 0;
}
table.logi-out th{
  width: 49% !important;
  padding: 0.5mm;
}